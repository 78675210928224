import ApiService from "@/core/services/api.service.js";

const customerService = {
    getCustomerList(param) {
        return  new Promise((resolve, reject) => {
          
          var paramStr = ''
          if(param.page != undefined){
            paramStr = '?page='+(param.page+1)
          }else{
            paramStr = '?'
          }
          for (const key in param.filters) {
            if(param.filters[key] != null)
              paramStr += '&' + key + '=' + param.filters[key]
          }
          
          ApiService.get("api/admin/customers/list"+paramStr)
            .then(function(response) {
              if (response.data) {
                resolve(response.data);
              } else {
                resolve("");
              }
            })
            .catch(function(error) {
              resolve("");
              reject(error)
            });
        });
    },

}

export default customerService;