<template>
  <b-card no-body class="card-custom">
    <template #header>
      <div class="card-title">
        <h3 class="card-label">Users</h3>
      </div>
    </template>

    <div class="card card-custom gutter-b card-stretch card-shadowless">
      <!-- User Interface controls -->
      <div class="card-body pt-0">
        <DataTable
          ref="dt"
          :value="users"
          dataKey="id"
          :lazy="true"
          :paginator="true"
          :rows="rowCount"
          class="mt-0"
          :totalRecords="totalRecords"
          @page="onPage($event)"
          @sort="onSort($event)"
          :loading="loading"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
        >
          <b-card-header class="p-4">
            <b-row>
              <b-col lg="2" md="3" sm="6">
                <b-form-group class="m-0">
                  <b-form-select
                    v-model="filters.status"
                    placeholder="Order Status"
                    :options="statusList"
                    @change="onFilter()"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col lg="2" md="3" sm="6">
                <b-form-group class="m-0">
                  <span class="p-input-icon-right w-100">
                    <b-form-input
                      v-model="filters.name"
                      placeholder="Search Name.."
                      class="py-2"
                      @keydown.enter="onFilter()"
                    />
                    <i class="las la-search"></i>
                  </span>
                </b-form-group>
              </b-col>
              <b-col lg="2" md="3" sm="6">
                <b-form-group class="m-0">
                  <span class="p-input-icon-right w-100">
                    <b-form-input
                      v-model="filters.email"
                      placeholder="Search email ID.."
                      class="py-2"
                      @keydown.enter="onFilter()"
                    />
                    <i class="las la-search"></i>
                  </span>
                </b-form-group>
              </b-col>
              <b-col lg="2" md="3" sm="6">
                <b-form-group class="m-0">
                  <span class="p-input-icon-right w-100">
                    <b-form-input
                      v-model="filters.phone"
                      placeholder="Search phone.."
                      class="py-2"
                      @keydown.enter="onFilter()"
                    />
                    <i class="las la-search"></i>
                  </span>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-header>

          <template #empty>
            <div class="text-center text-muted py-5">User not found.</div>
          </template>

          <Column field="sl" header="#" class="SL-n">
            <template #body="slotProps">
              {{ slotProps.index + 1 }}
            </template>
          </Column>
          <Column field="name" header="Name" :sortable="true">
            <template #body="slotProps">
              {{ slotProps.data.name }}
            </template>
          </Column>
          <Column field="customer" header="Email" :sortable="true">
            <template #body="slotProps">
              {{ slotProps.data.email }}
            </template>
          </Column>
          <Column field="reason" header="phone" :sortable="true">
            <template #body="slotProps">
              {{ slotProps.data.phone }}
            </template>
          </Column>

          <Column
            field="order_search"
            header="Status"
            ref="order_search"
            :sortable="true"
          >
            <template #body="slotProps">
              <div>{{ slotProps.data.status }}</div>
            </template>
          </Column>

          <!-- <Column field="action" header="Action" :sortable="true">
              <template #body="slotProps">
                <b-button
                  size="sm"
                  pill
                  :to="`/cancellation-status-form/${slotProps.data.id}`"
                  class="py-2 px-4 border bg-white bg-hover-primary"
                  >Change Status</b-button
                >
                <b-button
                  size="sm"
                  pill
                  :to="`/order-detail/${slotProps.data.id}`"
                  class="py-2 px-4 border bg-white bg-hover-primary"
                  >Detail</b-button
                >
              </template>
            </Column> -->
        </DataTable>
      </div>
    </div>
  </b-card>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";

import CustomerService from "@/core/services/api/customer";

export default {
  components: {
    DataTable,
    Column,
    InputText,
    Dropdown,
  },
  data() {
    return {
      statusList: [
        { value: null, text: "All" },
        { value: "active", text: "Active" },
        { value: "inactive", text: "Inactive" },
      ],
      order_search: null,
      order_type: null,
      users: [],
      imgPath: process.env.VUE_APP_API_STORAGE_URL,
      loading: false,
      totalRecords: 0,
      rowCount: 1,
      lazyParams: {},
    };
  },
  mounted() {
    this.loading = true;
    this.cancellationLoading = true;

    this.lazyParams = {
      first: 0,
      rows: this.$refs.dt.rows,
      sortField: null,
      sortOrder: null,
      filters: this.filters,
    };

    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Dashboard",
        route: "/dashboard",
      },
      {
        title: "Users",
        route: "/customer",
      },
    ]);
    this.getCustomerList();
  },
  computed: {
    filters: function () {
      var filters = {
        name: null,
        email: null,
        phone: null,
        customer_id: null,
        status: null,
      };
      return filters;
    },
  },
  methods: {
    async getCustomerList() {
      var response = await CustomerService.getCustomerList(this.lazyParams);
      if (response != undefined) {
        this.users = response.data;
        this.totalRecords = response.total;
        this.rowCount = response.per_page;
      } else {
        this.users = [];
        this.totalRecords = 0;
        this.rowCount = 1;
      }

      this.loading = false;
    },
    onPage(event) {
      this.loading = true;
      this.lazyParams = event;
      this.getCustomerList();
    },
    onSort(event) {
      this.loading = true;
      this.lazyParams = event;
      this.getCustomerList();
    },
    onFilter() {
      this.loading = true;
      this.lazyParams.filters = this.filters;
      this.getCustomerList();
    },
  },
  watch: {
    // order_search(newValue) {
    // 	this.filters.order_search = newValue;
    // 	this.onFilter();
    // }
  },
};
</script>

<style lang="scss">
.p-datatable {
  .p-datatable-wrapper {
    .p-datatable-table {
      tr {
        td:last-child {
          width: 100px;
        }
      }
    }
  }
}
</style>
